.Answer_label {
    display: block;
    font-size: 1.1em;
    padding: 13px 12px;
    border: 1px solid white;
    border-radius: 5px;
    margin: 14px 0;
    cursor: pointer;

    &:hover { /* TODO Focus etc  */ 
        background-color: #A95600 !important;
    }
}
