.LevelCompleted {
    text-align: center;

    p {
        margin: 20px 0;
        
    }

    img {
        display: block;
        max-width: 100%;
        margin: auto;
        border-radius: 20px;
    }

    button {
        margin-top: 20px;
    }

    .hashtag {
        font-size: 1.5rem;
        margin-top: 30px; 
    }
}

footer {
    padding: 40px 0 20px 0;
    font-size: 0.8rem;
    text-align: center;
    margin-top: auto;

    a {
        margin-left: 0.3em;
    }
}
