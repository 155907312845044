.Welcome {
    p {
        margin-top: 20px;
        font-size: 1.1em;
    }

    button {
        margin-top: 30px;
    }
}
