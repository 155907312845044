$timeLimit: 10s;

@keyframes timer {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

.Timer_outoftime {
    position: relative;
    top: -5px;
    text-align: center;
    color: red;
    height: 20px;
    font-size: 1.3em;
}

.Timer_container {
    position: relative;
    height: 20px;

    div {
        background-color: #0085C0;
        height: 100%;
        position: absolute;
        right: 0;
    }
}

.Timer_active {
    animation: timer $timeLimit linear;
}
