.GameCompleted {
    text-align: center;

    h1 {
        text-transform: uppercase;
    }

    p {
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
    }

    .hashtag {
        font-size: 1.5rem; 
    }

    img {
        display: block;
        margin: auto;
        border-radius: 30px;
    }

    button {
        margin-top: 30px;
    }

    p.boxout {
        border: 1px solid white;
        padding: 30px;
        border-radius: 20px;
    }
}