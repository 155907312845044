.Score {
    position: relative;
    height: 19px;
    margin: 8px 0;

    span {
        position: absolute;
        right: 0;
    }

    div {
        position: absolute;
        right: 0;
        opacity: 0;
        z-index: 1000;
        font-weight: bold;
    }

    .animated-score {
        animation: animatedScore ease 2s;
    }
    @keyframes animatedScore {
        0% {
            opacity: 0;
            font-size: 1.3em;
        }
        20% {
            opacity: 1;
            font-size: 5.6em;
        }
        100% {
            opacity: 0;
            font-size: 1.7em;
        }
    }

}
