.LevelResultMsg {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 12px;    
    text-transform: uppercase;
    font-weight: bold;
}

.LevelResultMsg_watch {
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 10px;
    font-size: 0.55em;
    padding: 1px 6px;
    /* width: 3.7em;
    background-repeat: no-repeat;
    background-size: 0.8em;
    background-position: 100% 100%;
    background-image: url(../images/external-link.png); */
}
