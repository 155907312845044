.VideoEmbed {
    position: relative; 
    padding-top: 56%;
    height: 0;
    background-image: url(../images/loading.gif);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 20%;

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}
