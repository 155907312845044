@import-normalize;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  line-height: 1.2;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;

  background-image: url(images/background.jpg);
  background-position: center;

  color:white;

  padding: 0 15px;
}


h1 {
  color: #c4c4c4;
}


button {
    display: block;
    margin: 10px auto;
    padding: 8px 25px;
    font-size: 1.2em;
    text-transform: uppercase;
}

a {
  color: inherit;
}
